import dayjs from '@/libs/dayjs'

const quickDateLists = [
  { text: 'Today', value: 'Today' },
  { text: 'Yesterday', value: 'Yesterday' },
  { text: 'Last 7 Days', value: 'Last 7 Days' },
  { text: 'Last 30 Days', value: 'Last 30 Days' },
  { text: 'This Month', value: 'This Month' },
  { text: 'Last Month', value: 'Last Month' },
]

const getQuickDateValue = quickDateKey => {
  let addDateStart = 0
  let addDateEnd = 0
  let fixedDateStart
  let fixedDateEnd
  const now = new Date()
  // eslint-disable-next-line default-case
  switch (quickDateKey) {
    case 'Today':
      addDateStart = 0
      addDateEnd = 1
      break
    case 'Yesterday':
      addDateStart = -1
      addDateEnd = 0
      break
    case 'Last 7 Days':
      addDateStart = -7
      addDateEnd = 0
      break
    case 'Last 30 Days':
      addDateStart = -30
      addDateEnd = 0
      break
    case 'This Month':
      fixedDateStart = dayjs(now).startOf('month').format('YYYY-MM-DD')
      addDateEnd = 1
      break
    case 'Last Month':
      fixedDateStart = dayjs(now).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      fixedDateEnd = dayjs(now).subtract(1, 'month').endOf('month').add(1, 'days')
        .format('YYYY-MM-DD')
      break
  }

  let dateStart
  let dateEnd
  const timeStart = '00:00'
  const timeEnd = '00:00'
  if (fixedDateStart) {
    dateStart = fixedDateStart
  } else if (addDateStart >= 0) {
    dateStart = dayjs(now).add(addDateStart, 'days').format('YYYY-MM-DD')
  } else {
    dateStart = dayjs(now).subtract(Math.abs(addDateStart), 'days').format('YYYY-MM-DD')
  }

  if (fixedDateEnd) {
    dateEnd = fixedDateEnd
  } else if (addDateEnd >= 0) {
    dateEnd = dayjs(now).add(addDateEnd, 'days').format('YYYY-MM-DD')
  } else {
    dateEnd = dayjs(now).subtract(Math.abs(addDateEnd), 'days').format('YYYY-MM-DD')
  }

  return {
    dateStart,
    dateEnd,
    timeStart,
    timeEnd,
  }
}

const parseQuickDateMatched = ({
  fromDateStart,
  fromDateEnd,
  fromTimeStart,
  fromTimeEnd,
}) => {
  let resultQuickDate = null
  const quickDateListsValues = Object.values(quickDateLists)
  for (let index = 0; index < quickDateListsValues.length; index += 1) {
    const quickDateListsValue = quickDateListsValues[index];
    const quickDateKey = quickDateListsValue.value
    const quickDate = getQuickDateValue(quickDateKey)
    const { dateStart } = quickDate
    const { dateEnd } = quickDate
    const { timeStart } = quickDate
    const { timeEnd } = quickDate
    if (fromDateStart === dateStart && fromDateEnd === dateEnd && fromTimeStart === timeStart && fromTimeEnd === timeEnd) {
      resultQuickDate = quickDateKey
      break
    }
  }
  return resultQuickDate
}

export {
  getQuickDateValue,
  quickDateLists,
  parseQuickDateMatched,
}

export const _ = undefined
