import Vue from 'vue'
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
// dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

// Vue.prototype.$dayjs = dayjs

dayjs.tz.setDefault("Asia/Bangkok")

export default dayjs
