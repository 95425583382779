<template>
  <!-- table -->
  <VueGoodTableRemote
    ref="tableData"
    :columns-top="columnsTop"
    :columns="columns"
    :rows="dataLists"
    :line-numbers="true"
    theme="black-rhino"
    :is-loading="$wait.is('loading-logs')"
    :pagination-enabled="true"
    :total-records="totalRecords"
    :select-enabled="false"
    @onLoadData="onLoadData"
  >

    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Action -->
      <span
        v-if="props.column.field === 'total'"
        class="text-total"
        :class="[props.formattedRow['transferType'] === 'INCREMENT' ? 'green--text' : 'red--text']"
      >{{ DecimalFormat(props.formattedRow[props.column.field]) }}</span>
      <span v-else-if="NumberFieldLists.includes(props.column.field)">
        {{ DecimalFormat(props.formattedRow[props.column.field]) }}
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- summary -->
    <template slot="table-actions-bottom2">
      <tr class="tr-on-hover">
        <td
          :colspan="getColumnSpanTotal.tableActionBottom"
          class="text-center black--text text-total"
        >
          <span>{{ $t('Summary') }}</span>
        </td>
        <td
          class="text-right text-totalblack--text"
        >
          {{ DecimalFormat(summary.totalAll) }}
        </td>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    </template>

  </VueGoodTableRemote>
</template>

<script>
import { DecimalFormat } from '@/utils/number'
import VueGoodTableRemote from '@/components/VueGoodTableRemote.vue'
import Button from '@/components/Button.vue'
import Loading from '@/components/LoadingComponent.vue'
import store from '@/store'

export default {
  components: {
    VueGoodTableRemote,
    Button,
    Loading,
  },
  props: {
    dataLists: {
      type: Array,
      required: true,
    },
    summary: { type: Object, required: true },
    totalRecords: { type: Number, required: true },
    isMember: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      NumberFieldLists: [
        'member_before',
        'member_after',
        'upline_before',
        'upline_after',
      ],
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    bgTotalCom() {
      return 'table__tdclass__lists'
    },
    getColumnSpanTotal() {
      return {
        tableActionBottom: 4,
        headerTop: 6,
      }
    },
    columnsTop() {
      const headers = [
        {
          colspan: this.getColumnSpanTotal.headerTop,
        },
        {
          label: this.isMember ? this.$t('Member') : this.$t('Agent'),
          colspan: 2,
          style: {
            'text-align': 'center',
          },
        },
        {
          label: this.$t('Upline'),
          colspan: 2,
          style: {
            'text-align': 'center',
          },
        },
      ]
      return headers
    },
    columns() {
      let headers = []
      headers = [...headers,
        {
          label: 'Date',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`, // 2021-10-03T15:40:50.621Z
          dateOutputFormat: 'yyyy-MM-dd HH:mm:ss', // 2021-10-03 15:40:50
          width: '190px',
        },
        {
          label: 'ActionBy',
          field: 'actionByUsername',
          width: '130px',
        },
        {
          label: this.isMember ? 'Member' : 'Agent',
          field: 'member_username',
          width: '130px',
        },
        {
          label: 'Amount',
          field: 'total',
          type: 'number',
        },
        {
          label: 'TransferType',
          field: 'transferType',
        },
        {
          label: 'Source',
          field: 'source',
        },
        {
          label: 'Before',
          field: 'member_before',
          type: 'number',
          sortable: false,
        },
        {
          label: 'After',
          field: 'member_after',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Before',
          field: 'upline_before',
          type: 'number',
          sortable: false,
        },
        {
          label: 'After',
          field: 'upline_after',
          type: 'number',
          sortable: false,
        },
      ]
      return headers
    },
  },
  mounted() {
  },
  methods: {
    getTableDataParams() {
      try {
        const params = this.$refs.tableData.getParams()
        return params
      } catch (error) {
        return {}
      }
    },
    DecimalFormat,
    onLoadData() {
      this.$emit('onLoadData')
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
