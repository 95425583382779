<template>
  <b-button
    :variant="$attrs.variant || 'primary'"
    :disabled="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="!loading">
      <feather-icon
        v-if="icon"
        :icon="icon"
      />
      <span
        v-if="text"
        class="align-middle"
      >
        {{ text }}
      </span>
    </div>
    <div v-else>
      <b-spinner
        small
        type="grow"
      />
      <span v-if="text">{{ text }}</span>
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: { BButton, BSpinner },
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    text: { type: String, required: false, default: null },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style>

</style>
